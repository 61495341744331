<template>
  <div class="row">
    <modal :adaptive="true" height="auto" name="open_to_settings_table" width="300">
      <div class="row mx-0 py-7">
        <div class="col-12 row mx-0 py-2 navy-blue-border-bottom-1px text-xl font-weight-bold">
          <div class="col-6 text-center">{{ $t('general.colum_name') }}</div>
          <div class="col-6 text-center">{{ $t('general.add') }}</div>
        </div>
        <div class="col-12  mx-0 py-2 text-xl">
          <draggable v-model="fieldsToViewEdit" v-bind="dragOptions" :move="onMove" class="list-group py-4" tag="ul"
            @end="isDragging=false" @start="isDragging=true">
            <transition-group :name="'flip-list'" type="transition">
              <div v-for="element in fieldsToViewEdit" :key="element.sort"
                class="col-12 list-group-item w-300px d-flex justify-content-between ">
                <span class="col-6"> {{ $t(element.name) }}</span>

                <span class="col-6 d-flex justify-content-center">
                  <input v-model="element.type_status" style="font-size: 10px " type="checkbox"></span>
              </div>
            </transition-group>
          </draggable>
          <button class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeModal">
            {{ $t('esc.new_send_close').toUpperCase() }}
          </button>
          <button class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer"
            @click="onSubmitToSaveSettingTable">{{
            $t('esc.new_send')
            }}
          </button>
        </div>

      </div>
    </modal>
    <div class="col-md-12">
      <form @submit.prevent="filterResult(null)">
        <div id="filter-card" class="row py-3 px-4" v-if="filters">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 my-2 row px-0 mx-0 py-1">
                <input-filter :helper-text="$t('general.search')" :model.sync="filters.search"
                  :placeholder="$t('general.search_filter')" title="general.search"></input-filter>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 my-lg-3 my-sm-0 row mx-0 px-0 py-1">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter :helper-text="$t('general.start_date')" :model.sync="filters.start_date"
                    name="start_time" title="general.start_date">
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter :helper-text="$t('general.end_date')" :model.sync="filters.end_date" name="end_date"
                    title="general.end_date">
                  </date-filter>
                </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 my-lg-2 my-2 my-sm-0 row mx-0 px-0">
                  <div class="col-md-12 my-md-0">
                    <select-filter :helper-text="$t('sample.status_by_filter')" title="general.status_filter"
                      :model.sync="filters.invoice_status_id" :item-per-row="1" :max="1" 
                      :not-list="true" :values="sampleStatuses" height="30vh" style="margin-bottom: 0 !important;" 
                      width="200px"></select-filter>
                  </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 my-lg-2 my-2 my-sm-0 row mx-0 px-0">
                  <div class="col-md-12 my-md-0">
                    <select-filter :helper-text="$t('general.by_invoices_types')" title="general.by_invoices_types"
                      :model.sync="filters.overhead_invoice" :item-per-row="1" :max="1" 
                      :not-list="true" :values="invoicesTypes" height="30vh" style="margin-bottom: 0 !important;" 
                      width="200px"></select-filter>
                  </div>
              </div>
              <div
                class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult(null)">{{
                    $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters">
                    {{ $t('general.clear') }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button @click="exportInvoiceTable" type="button"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.export_to_excel') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="px-5 py-3">
            <div class="fixed" style="position: fixed !important; top: 255px; right: 30px;">
              <div class="w-60px h-60px" @click="openPopToSettingTable">
                <span v-html="getIconByKey('icons.waybill.edit', {
                  class: 'w-40px h-40px d-inline-block object-fill cursor-pointer'
                })">
                </span>
              </div>
            </div>
            <data-table 
                :bordered="false" 
                :borderless="true" 
                :fields.sync="tableFields" 
                :hover="false"
                :items="formattedItems" 
                :no-border-collapse="true" 
                :outlined="false" 
                :striped="false" 
                tableVariant="''"
                :sortBy.sync="order" 
                :sortDesc.sync="sort"
                @hitBottom="onHitBottom" 
                @rowClicked="onRowClicked" 
                :infiniteId.sync="infiniteId"
            ></data-table>
            <div class="fixed" style="position: fixed !important; bottom: 20px; right: 40px;"
              v-if="isUserGranted('Invoice', ['create'], false)">
              <div class="w-60px h-60px" @click="openCreateNewInvoice">
                <span v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
                })">
                </span>
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import { mapGetters, mapMutations } from 'vuex'
import {
  GET_ITEMS,
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  RESET_VALUES,
  UPDATE_ITEM_BY_ID,
  EXPORT,
  FILTER,
  SET_FILTER
} from "@/core/services/store/invoice/incoming_invoice.module";
import moment from "moment";
import DateFilter from "@/assets/components/filters/DateFilter";
import InputFilter from "@/assets/components/filters/InputFilter";
import DataTable from "@/assets/components/dataTable/DataTable";
import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import draggable from 'vuedraggable'
import { SET_FILTER_LOGO } from "@/core/services/store/waybill/waybill.module";
import {INVOICE_INDEX_END_DATE_STORAGE_NAME, INVOICE_INDEX_START_DATE_STORAGE_NAME} from "@/core/storage/storage-names"

export default {
  name: "InvoiceIndex",
  components: {
    DashboardBox,
    DateFilter,
    InputFilter,
    DataTable,
    draggable,
    SelectFilter
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },
    invoicesTypes(){
      let types=[
        {
          id:0,
          name:'Faturalar'
        },
        {
          id:1,
          name:'Genel Gider Faturaları'
        }
      ];
      types=this.convertArrayToObjectByKey(types, 'id', 'name');
      return types;
    },
    formattedItems() {
      if (!this.items || this.items.data === null || this.items.data === undefined) return [];
      return this.items.data.map(invoice => {
        let currency = invoice.currency_unit && invoice.currency_unit.code ? invoice.currency_unit.code : this.defaultCurrency;


        return {
          'id': invoice.id,
          'operation_date': moment(invoice.operation_date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
          'invoice_date': moment(invoice.invoice_date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
          'supplier_company_id': invoice.supplier_company.name_for_accounting,
          'invoice_type_id': invoice.invoice_type.translations[0].name,
          'description': invoice.format_utf8_description?invoice.format_utf8_description:'-',
          'invoice_kind_id': invoice.invoice_category?invoice.invoice_category.translations[0].name:'-',
          'invoice_no': invoice.invoice_no,
          'amount_to_be_paid': `${Number(invoice.amount_to_be_paid).toLocaleString('tr-TR')} ${currency}`,
          'invoice_status_id': invoice.status_id,
          'invoice_status': invoice.status ? invoice.status.translations[0].name:'Null',
          'invoice_value': null,
          'accounting_description':invoice.accounting_description?invoice.accounting_description:'-'
        }
      })
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    tableFields() {
      let fields = [];
      let self = this;
      for (const [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
        if (value.status === true) {
          fields.push({
            'key': key,
            'value': this.filter && this.filter.hasOwnProperty(key) ? this.filter[key] : '',
            'label': this.$t(value.name),
            'sortable': value.status,
            'sort': value.sort,
            'class': "text-center border border-top-0 border-left-0",
            'tdClass': "cursor-pointer",
          })
        }
      }
      return _.sortBy(fields, ['sort']);
    }
  },
  data() {
    return {
      infiniteId: 1,
      editable: true,
      defaultCurrency: 'TL',
      isDragging: false,
      delayedDragging: false,
      sampleStatuses: [],
      fieldsToViewEdit: [],
      url: 'api/invoice',
      items: { data: [] },
      startDate: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
      endDate: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
      page: 1,
      perPage: 25,
      sort:"desc",
      order: "id",
      modelTableItem: [
        {
          user_id: null,
          model_name: null,
          table_fields: {},
        }
      ],
    };
  },
  methods: {
    ...mapMutations({
      'setFilter': SET_FILTER,
    }),
    openCreateNewInvoice() {
      this.$router.push({ name: 'invoice.create' })
    },
    getTableItem() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/model_table',
        filters: {
          model_name: 'invoice'
        },
        acceptPromise: true,
        showLoading: false,
      }).then(result => {
        if (result.status) {
          for (let i = 0; i < result.data.length; i++) {
            self.modelTableItem = result.data;
            self.modelTableItem[i].table_fields = JSON.parse(result.data[i].table_fields)
          }
          for (const [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
            self.fieldsToViewEdit.push({
              'name': this.$t(value.name),
              'sort': value.sort,
              'type': value.type,
              'type_status': value.status,
              'key': key,
            })
            self.fieldsToViewEdit = _.sortBy(self.fieldsToViewEdit, ['sort']);
          }
        }
      });
    },
    orderList() {
      this.modelTableItem[0].table_fields = this.modelTableItem[0].table_fields.sort((one, two) => {
        return one.sort - two.sort;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.status) && !draggedElement.status
      );
    },
    openPopToSettingTable() {
      this.$modal.show('open_to_settings_table')
    },
    closeModal() {
      this.$modal.hide('open_to_settings_table');
    },
    onSubmitToSaveSettingTable() {
      let self = this;
      let json = {};
      for (let i = 0; i < this.fieldsToViewEdit.length; i++) {
        json[this.fieldsToViewEdit[i].key] = {
          name: this.fieldsToViewEdit[i].name,
          type: this.fieldsToViewEdit[i].type,
          status: this.fieldsToViewEdit[i].type_status,
          sort: i,
        }
      }
      let contents = {
        model_name: 'invoice',
        table_fields: json,
      }
      let payload = {
        url: 'api/model_table/' + this.modelTableItem[0].id,
        id: this.id,
        contents: contents,
      }
      this.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then(result => {
        if (result.status) {
          this.$modal.hide('open_to_settings_table');
          self.sweetAlertSuccess(self.$t('general.successfully_created'));
          this.fieldsToViewEdit = [];
          this.getTableItem();

        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
      });
    },
    filterResult() {
      localStorage.setItem(INVOICE_INDEX_END_DATE_STORAGE_NAME, moment(this.filters.start_date));
      localStorage.setItem(INVOICE_INDEX_START_DATE_STORAGE_NAME, moment(this.filters.end_date));

      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onHitBottom();
      if (self.state) {
        setTimeout(() => {
          self.state.reset();
        }, 100);
      }
    },
    onHitBottom($state = null) {
      let self = this;
      if (self.isUserGranted('Invoice', ['viewAny'])) {

        let filters = _.cloneDeep(this.filters);
        this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
        this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
        this.$set(filters, 'order', this.order);
        this.$set(filters, 'sort', this.sort);

        self.$store.dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.url,
          filters: filters,
          '$state': $state,
        }).then(result => {
          if (result.status) {
            self.items = result.data;
            if (!(result.data && result.data.data && result.data.data.length)) {
              $state.complete();
            }
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        })
      }
    },
    resetFilters() {
      this.sort="desc";
      this.order= "id";
      this.$store.commit(RESET_VALUES)
      this.setFilter({
        start_date: moment().subtract(30, 'd'),
        end_date: moment().add(1, 'd'),
        search: null,
        sort: "",
        order: "",
        is_service: 0,
        page: 1,
        per_page: 25,
      })
      this.infiniteId++
    },
    onRowClicked(item, index, event) {
      if (this.isUserGranted('Invoice', ['view', 'update', 'view'])) {
        this.$router.push({
          name: 'invoice.details',
          params: {
            id: this.items.data[index].id,
          }
        });
      }

      return false;
    },
    exportInvoiceTable() {
      let filters = _.cloneDeep(this.filters);
      this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'order', this.order);
      this.$set(filters, 'sort', this.sort);
      this.$store.commit(SET_LOADING,true);
      this.$store.dispatch(EXPORT, {
        filters: filters
      }).then(result => {
        if(result.status) {
          let fileURL = window.URL.createObjectURL(new Blob([result.data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let day = moment().format('YYYYMMDDHHMMSS');
          let fileName = 'invoice' + day + '.xlsx';
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          this.$store.commit(SET_LOADING,false);
          fileLink.click();
        }
      })
    },
    async getStatuses() {
      let self = this;
      let statuses = [4, 6];
      const tmpStatuses = [];

      for (const status of statuses) {
        const result = await this.$store.dispatch(GET_ITEMS, {
          url: 'api/statuses',
          filters: {
            type_id: status
          },
          acceptPromise: true,
          showLoading: false,
        });

        if (result.status) {
          tmpStatuses.push(...result.data);
        }
      }

      this.sampleStatuses = this.convertArrayToObjectByKey(tmpStatuses, 'id', 'translations.0.name');
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('general.dashboard'), route: 'dashboard' },
      { title: this.$t('invoice.invoices') }
    ]);
    let self = this;

    if (this.isUserGranted('Invoice', ['viewAny'])) {
      if (!this.filter) {
        this.resetFilters();
      }
      this.filters.end_date = localStorage.hasOwnProperty(INVOICE_INDEX_START_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(INVOICE_INDEX_START_DATE_STORAGE_NAME))
      : moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);

     this.filters.start_date = localStorage.hasOwnProperty(INVOICE_INDEX_END_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(INVOICE_INDEX_END_DATE_STORAGE_NAME))
      : moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);

      let parameterOrderNumber = this.$route.params.order_number;
      if (parameterOrderNumber) {
        this.$set(this.filters, 'search', parameterOrderNumber)
      }
      this.getStatuses();

      this.getTableItem();
      setTimeout(this.onHitBottom, 1000);
    }
  },
  watch: {
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
